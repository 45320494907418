@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Black.eot');
  src: url('../fonts/Rubik/Rubik-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Rubik/Rubik-Black.woff2') format('woff2'),
      url('../fonts/Rubik/Rubik-Black.woff') format('woff'),
      url('../fonts/Rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Bold.eot');
  src: url('../fonts/Rubik/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Rubik/Rubik-Bold.woff2') format('woff2'),
      url('../fonts/Rubik/Rubik-Bold.woff') format('woff'),
      url('../fonts/Rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Light.eot');
  src: url('../fonts/Rubik/Rubik-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Rubik/Rubik-Light.woff2') format('woff2'),
      url('../fonts/Rubik/Rubik-Light.woff') format('woff'),
      url('../fonts/Rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Medium.eot');
  src: url('../fonts/Rubik/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Rubik/Rubik-Medium.woff2') format('woff2'),
      url('../fonts/Rubik/Rubik-Medium.woff') format('woff'),
      url('../fonts/Rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Regular.eot');
  src: url('../fonts/Rubik/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Rubik/Rubik-Regular.woff2') format('woff2'),
      url('../fonts/Rubik/Rubik-Regular.woff') format('woff'),
      url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

