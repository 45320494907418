.topPage {
	color: $footer-color;
	background: $header-bg;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 90px;
	
	.topPage-top {
		padding: 120px 0 0;
		.topPage-title {
		padding:5rem 0;
			font-size: 4rem;
			font-weight: 500;
			margin: 0;
			text-align: center;
		}
		
	}
	}
	
